import Lojas from 'Pages/Views/Pages/Lojas'
import Partners from 'Pages/Views/Pages/Partners'
import Manager from 'Pages/Views/Pages/Manager'
import DashBoard from 'Pages/Views/Pages/Dashboard'
import Themes from 'Pages/Views/Pages/Themes'
import User from 'Pages/Views/Pages/User'
import ProtectedRoute from 'Pages/Views/Pages/ProtectRoute'
import ChangePassword from 'Pages/Views/Pages/ChangePassword'
import { RouteObject } from 'react-router-dom'
import MainLayout from 'Layout'

const route:RouteObject = {
  path: '/',
  children: [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'alterar-senha',
          element: <ProtectedRoute><ChangePassword /></ProtectedRoute>,  
        },
        {
          path: '/',
          element: <ProtectedRoute><DashBoard /></ProtectedRoute>,
        },
        {
          path: 'meus-dados',
          element: <ProtectedRoute><User /></ProtectedRoute>,
        },
        {
          path: 'principal',
          element: <ProtectedRoute><DashBoard /></ProtectedRoute>,
        },
        {
          path: 'lojas',
          element: <ProtectedRoute><Lojas /></ProtectedRoute>,
        },
        {
          path: 'gerenciar',
          element: <ProtectedRoute><Manager /></ProtectedRoute>,
        },
        {
          path: 'temas',
          element: <ProtectedRoute><Themes /></ProtectedRoute>,
        }
      ]
    },
    {
      path: '*',
      element: <Partners />,
    },
  ]
}

export default route