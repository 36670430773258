import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { APICall } from 'Service/Api'
import { CardTemplate } from 'Components';
import { ContentBlock, FloatingTag, FloatBlock, Loading, ContentList } from 'Shared/Components'
import { usePartner } from 'Contexts';
import { useHeader } from 'Shared/Contexts';

const Lojas = () => {
  const { setHeaderTitle, setHeaderButton } = useHeader();

  const { user, logoutUser } = usePartner();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parceiro/lojas', user.PARC_TOKEN],
    queryFn: async () => await APICall(user.PARC_TOKEN, 'parceiro/lojas'),
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    setHeaderButton(undefined)
    setHeaderTitle('Lojas indicadas');
  }, []);

  useEffect(() => {
    if (isError && (error as any)?.erro == 400) {
      logoutUser();
    }
  }, [isError, error])

  return (
    <>
      {isLoading && <Loading />}

      {
        isError &&
        (
          <>
            <FloatBlock
              title={(error as any).erro == 404 ? 'Atenção' : 'Erro'}
              type={(error as any).erro == 404 ? 'info' : 'error'}
            >
              <span>{(error as any).mensagem}</span>
            </FloatBlock>
          </>
        )
      }

      {
        !isLoading &&
        data?.resumos &&
        <div className='Content_Divisor'>
          {
            data.resumos.map((resume: any) => (
              <CardTemplate
                {...resume}
              />
            ))
          }
        </div>
      }

      {
        !isLoading &&
        data &&
        <ContentList
          columns={{
            "Loja": (store: any) => (
              <>
                <h6>
                  <a href={'/lojas/' + store.LV_ID}>{store.LV_NOME}</a>
                </h6>
                <small style={{ lineBreak: 'anywhere' }}>{store.LVDOMADD_DOMINIO}</small>
              </>
            ),
            "Data de cadastro": (store: any) => (
              <span>{store.LV_DT_CADASTRO}</span>
            ),
            "Status": (store: any) => (
              <div className={store.LV_STATUS == '1' ? 'enable_tag' : 'disable_tag'}><FloatingTag relative={true} force={true} label={store.LV_STATUS == '1' ? 'Ativa' : 'Inativa'} /></div>
            )
          }}
          dataArray={data.lojas}
        />
      }


    </>
  )
}

export default Lojas