import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { ContentBlock, InputText, InputButton } from 'Shared/Components'
import { BlurContainer } from "Components"
import { useNotification, useTheme } from 'Shared/Contexts'
import isMobile from 'Shared/Utils/Functions/Mobile';
import AlternateLogo from 'Assets/Icons/AlternateLogo.svg'
import AlternateLogoWhite from 'Assets/Icons/AlternateLogoWhite.svg'
import { recoverPassService } from 'Service/Authenticate';

const Recover = () => {
  const [email, setEmail] = useState<string>('');
  const { setNotification } = useNotification();
  const { theme } = useTheme();

  const mutation = useMutation(recoverPassService, {
    mutationKey: 'recover' + email, 
    onSuccess: () => {
      setNotification({
        title: 'Enviado!',
        message: 'Os dados de acesso foram enviados para seu e-mail.',
        type: 'success'
      })
    },
    onError: (data:any) => {
      setNotification({
        title: "Falha no login",
        message: typeof data == 'string' ? data : 'Erro desconhecido.',
        type: "error",
      })
    }
  })

  const forgetPassword = (e: any) => {
    e.preventDefault();

    if (!email) setNotification({
      title: "Insira seu email.",
      type: "error",
    });

    mutation.mutate({ email })
  }

  return (
    <>
      <section id='Content_Section' className={isMobile() ? 'Mobile' : ''}>
        <main className='Login_Page'>
          <div className='Float_Logo'>
            <Link to={'/'} className='Justify_Center'>
              <img src={theme == 'default' ? AlternateLogo : AlternateLogoWhite} alt="" />
            </Link>
          </div>
          <ContentBlock>
            <div>
              <div className='Flex_Column Align_Center'>
                <h1>Painel do parceiro</h1>
              </div>
              <form onSubmit={forgetPassword} className='Flex_Column g-6'>
                <span>Informe o seu e-mail para enviarmos o lembrete de senha.</span>
                <InputText type="email" name='email' label='E-mail' placeholder='E-mail' max={50} value={email} required onChange={(e) => setEmail(e.target.value)} />
                <div>
                  <InputButton type="Add" className='Flex_Row'>Enviar</InputButton>
                </div>
                <div className='Justify_Center'>
                  <Link to={'/login'}>Voltar</Link>
                </div>
                <div className='Justify_Center'>
                  <span>Ainda não é parceiro? <Link to={'/cadastro'}>Cadastre-se</Link></span>
                </div>
              </form>
            </div>
          </ContentBlock>
        </main>
      </section>
      <BlurContainer />
    </>
  )
}

export default Recover