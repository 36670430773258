import React from "react";

const LetterBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.715"
      height="16.344"
      viewBox="0 0 20.715 16.344"
    >
      <path
        id="Caminho_11016"
        data-name="Caminho 11016"
        d="M2707.481,2106.551l-5.953-5.379m-5.128,0-5.953,5.379m-.448-9.861,7.315,5.128a4.238,4.238,0,0,0,1.219.7,1.746,1.746,0,0,0,.86,0,4.238,4.238,0,0,0,1.219-.7l7.315-5.128m-13.626,11.654h9.323a6.611,6.611,0,0,0,2.833-.3,2.57,2.57,0,0,0,1.174-1.174,6.611,6.611,0,0,0,.3-2.833V2098.3a6.611,6.611,0,0,0-.3-2.833,2.57,2.57,0,0,0-1.174-1.174,6.611,6.611,0,0,0-2.833-.3H2694.3a6.611,6.611,0,0,0-2.833.3,2.57,2.57,0,0,0-1.174,1.174,6.611,6.611,0,0,0-.3,2.833v5.737a6.611,6.611,0,0,0,.3,2.833,2.57,2.57,0,0,0,1.174,1.174A6.611,6.611,0,0,0,2694.3,2108.344Z"
        transform="translate(-2688.607 -2093)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LetterBox;
