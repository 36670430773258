import React from "react";

const Configs = ():JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Grupo_3828"
        data-name="Grupo 3828"
        transform="translate(-6665 -2661.9)"
      >
        <path
          id="Caminho_10999"
          data-name="Caminho 10999"
          d="M700,2397a3,3,0,1,0-3-3A3,3,0,0,0,700,2397Z"
          transform="translate(5976 278.9)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_11000"
          data-name="Caminho 11000"
          d="M706.727,2396.73a1.486,1.486,0,0,0-.1.87,1.462,1.462,0,0,0,.4.78l.055.06a1.831,1.831,0,0,1,.394.59,1.772,1.772,0,0,1,.139.69,1.823,1.823,0,0,1-.533,1.29,1.774,1.774,0,0,1-.59.39,1.8,1.8,0,0,1-1.393,0,1.764,1.764,0,0,1-.59-.39l-.054-.06a1.472,1.472,0,0,0-.782-.4,1.535,1.535,0,0,0-.873.1,1.516,1.516,0,0,0-.66.56,1.468,1.468,0,0,0-.249.82v.15a1.818,1.818,0,1,1-3.636,0v-.08a1.524,1.524,0,0,0-.28-.84,1.5,1.5,0,0,0-.7-.53,1.471,1.471,0,0,0-1.655.3l-.054.05a1.976,1.976,0,0,1-.59.4,1.8,1.8,0,0,1-1.393,0,1.962,1.962,0,0,1-.59-.4,1.85,1.85,0,0,1-.4-.59,1.815,1.815,0,0,1,.4-1.98l.054-.06a1.456,1.456,0,0,0,.4-.78,1.528,1.528,0,0,0-.654-1.53,1.5,1.5,0,0,0-.822-.25h-.155a1.82,1.82,0,0,1,0-3.64h.082a1.478,1.478,0,0,0,.838-.27,1.513,1.513,0,0,0,.535-.71,1.486,1.486,0,0,0,.1-.87,1.462,1.462,0,0,0-.4-.78l-.055-.06a1.831,1.831,0,0,1-.394-.59,1.772,1.772,0,0,1-.139-.69,1.823,1.823,0,0,1,.533-1.29,1.774,1.774,0,0,1,.59-.39,1.8,1.8,0,0,1,1.393,0,1.764,1.764,0,0,1,.59.39l.054.06a1.472,1.472,0,0,0,.782.4,1.535,1.535,0,0,0,.873-.1h.073a1.508,1.508,0,0,0,.659-.56,1.46,1.46,0,0,0,.25-.82v-.15a1.818,1.818,0,1,1,3.636,0v.08a1.485,1.485,0,0,0,.25.82,1.424,1.424,0,0,0,.659.55,1.471,1.471,0,0,0,1.655-.3l.054-.05a1.976,1.976,0,0,1,.59-.4,1.8,1.8,0,0,1,1.393,0,1.962,1.962,0,0,1,.59.4,1.85,1.85,0,0,1,.4.59,1.815,1.815,0,0,1-.4,1.98l-.054.06a1.456,1.456,0,0,0-.4.78,1.485,1.485,0,0,0,.1.87v.07a1.482,1.482,0,0,0,.55.66,1.5,1.5,0,0,0,.822.25h.155a1.819,1.819,0,0,1,1.285.53,1.828,1.828,0,0,1,0,2.58,1.819,1.819,0,0,1-1.285.53H708.1a1.5,1.5,0,0,0-1.373.91Z"
          transform="translate(5976 278.9)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default Configs;
