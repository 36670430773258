import { usePartner } from 'Contexts';
import { createLayout, createTheme } from 'Service/Action';
import { ContentBlock, InputButton, InputRadio, InputText, Loading } from 'Shared/Components'
import { useAlert, useNotification } from 'Shared/Contexts';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';

const CreatingTheme = ({ themeID, setCreatingTheme, storeId }: { themeID: string, setCreatingTheme: any, storeId?: string }) => {
  const { user, logoutUser } = usePartner();
  const [themeName, setThemeName] = useState('');
  const [themeType, setThemeType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setNotification } = useNotification();
  const { setCustomAlert } = useAlert();

  useEffect(() => {
    document.querySelector('main')?.scrollTo(0, 0)
  }, []);

  const mutationObj = {
    onSuccess: (data: any) => {
      setNotification({
        title: "Sucesso",
        message: themeID != '' ? "Tema atualizado com sucesso!" : "Tema criado com sucesso!",
        type: "success",
      })
      setIsLoading(false);
      setCreatingTheme(false);
    },
    onError: (message:any) => {
      setNotification({
        title: "Falha na criação de temas",
        message: typeof message == 'string' ? message : 'Erro desconhecido.',
        type: "error",
      })
    }
  }

  const mutation = useMutation(createLayout, mutationObj)
  const mutation2 = useMutation(createTheme, mutationObj)

  const createThemeFunction = (e: any) => {
    e.preventDefault();

    setCustomAlert({
      label: "Atenção!",
      content: `Tem certeza que deseja criar um novo ${storeId ? "Tema" : "Layout"}`,
      confirmAction: () => {
        if (storeId) {
          mutation2.mutate({
            dispositivo: themeType,
            token: user.PARC_TOKEN,
            storeId: storeId
          })
        } else {
          mutation.mutate({
            dispositivo: themeType,
            token: user.PARC_TOKEN,
            nome: themeName,
            id: themeID
          })
        }
        
        setIsLoading(true);
      }
    })
  }

  return (
    <>
      {
        <ContentBlock>
          <header>
            <h4>Criando tema</h4>
            <small>Informe os dados do tema abaixo.</small>
          </header>
          <div>
            {
              isLoading ?
                <Loading />
                :
                <form onSubmit={createThemeFunction} className='Flex_Column g-2'>
                  <div>
                    {
                      !storeId &&
                      <InputText
                        label='Nome'
                        size='Medium'
                        name='Theme_Name'
                        onChange={(e) => setThemeName(e.target.value)}
                        required={true}
                      />
                    }
                  </div>
                  <div>
                    <label>Dispositivos</label>
                    <InputRadio
                      label='Desktop/Mobile'
                      onChange={(e) => e?.target.checked ? setThemeType('D') : null}
                      name='Theme_Type'
                    />
                    <InputRadio
                      label='Mobile'
                      onChange={(e) => e?.target.checked ? setThemeType('M') : null}
                      name='Theme_Type'
                    />
                  </div>
                  <div className='Flex_Row'>
                    <InputButton type='More' onClick={() => setCreatingTheme(false)}>Cancelar</InputButton>
                    <InputButton type='Save'>Criar tema</InputButton>
                  </div>
                </form>
            }

          </div>
        </ContentBlock>
      }
    </>
  )
}

export default CreatingTheme