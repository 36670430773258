import React from "react";

const Letter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.785"
      height="18"
      viewBox="0 0 22.785 18"
    >
      <path
        id="Caminho_11210"
        data-name="Caminho 11210"
        d="M2690,2057l8.16,5.72a4.722,4.722,0,0,0,1.36.78,1.948,1.948,0,0,0,.96,0,4.722,4.722,0,0,0,1.36-.78L2710,2057m-15.2,13h10.4a7.375,7.375,0,0,0,3.16-.33,2.868,2.868,0,0,0,1.31-1.31,7.375,7.375,0,0,0,.33-3.16v-6.4a7.375,7.375,0,0,0-.33-3.16,2.868,2.868,0,0,0-1.31-1.31,7.375,7.375,0,0,0-3.16-.33h-10.4a7.375,7.375,0,0,0-3.16.33,2.868,2.868,0,0,0-1.31,1.31,7.375,7.375,0,0,0-.33,3.16v6.4a7.375,7.375,0,0,0,.33,3.16,2.868,2.868,0,0,0,1.31,1.31A7.375,7.375,0,0,0,2694.8,2070Z"
        transform="translate(-2688.607 -2053)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Letter;
