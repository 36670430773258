import Login from 'Pages/Authenticate/Pages/Login'
import Register from 'Pages/Authenticate/Pages/Register'
import Recover from './Pages/Recover'
import { RouteObject } from 'react-router-dom'

const route:RouteObject = {
  path: '/',
  children: [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'recover',
      element: <Recover />,
    },
    {
      path: 'cadastro',
      element: <Register />,
    }
  ]
}

export default route