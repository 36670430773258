import { InputText, InputButton, InputSelect, InputCheckbox, InputTextArea } from 'Shared/Components'
import { BlurContainer } from "Components"
import isMobile from "Shared/Utils/Functions/Mobile"
import { useMutation } from 'react-query'
import { createUser } from 'Service/Authenticate'
import { Link, useNavigate } from 'react-router-dom'
import { useNotification, useTheme } from 'Shared/Contexts'
import AlternateLogo from 'Assets/Icons/AlternateLogo.svg'
import AlternateLogoWhite from 'Assets/Icons/AlternateLogoWhite.svg'

const Register = () => {
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const { theme } = useTheme();

  const mutation = useMutation(createUser, {
    onMutate: () => {
      setNotification({
        type: "hidden",
      });
    },
    onSuccess: (data: any) => {
      setNotification({
        title: 'Sucesso',
        message: 'Um email foi enviado para você.',
        type: "success",
      });
      setTimeout(() => {
        setNotification(false);
        navigate("/principal");
      }, 1250);
    },
    onError: (data: any) => {
      setNotification({
        title: 'Falha',
        message: data || 'Tente novamente por favor.',
        type: "error",
      });
    }
  })

  const logData = (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formObject: any = {
      "varCheckOpc1": 'false',
      "varCheckOpc2": 'false',
      "varCheckOpc3": 'false',
      "varCheckOpc4": 'false',
      "varCheckOpc5": 'false',
      "varCheckOpc6": 'false',
      "varCheckOpc7": 'false',
      "varCheckOpc8": 'false',
      "varTxtCNPJ": "number",
      "varTxtCpfContato": "number",
      "varTxtFoneCel": "number",
      "varTxtFoneFixo": "number",
      "varTxtCep": "number"
    };

    formData.forEach((value, key) => {
      if (formObject[key] == 'number') formObject[key] = value.toString().replace(/\D/g, '');
      else formObject[key] = value;
    });

    mutation.mutate(formObject);
  }

  return (
    <>
      <section id='Content_Section' className={isMobile() ? 'Mobile' : ''}>
        <main className='Register_Page'>
          <div className='Float_Logo'>
            <Link to={'/'} className='Justify_Center'>
              <img src={theme == 'default' ? AlternateLogo : AlternateLogoWhite} alt="" />
            </Link>
          </div>
          <div className='Float_Block'>
            <div className='Flex_Column Align_Center'>
              <h1>Cadastro de parceiros</h1>
            </div>
            <div>
              <form onSubmit={logData} className='Flex_Column g-5 p-2'>
                <InputSelect
                  name='varTxtOndeConheceu'
                  label='Onde conheceu a Webstore:'
                  placeholder='Escolha...'
                  required={true}
                  // defaultClear={false}
                  defaultMaxOptions={1}
                  options={[
                    {
                      value: '1',
                      label: 'Google'
                    },
                    {
                      value: '2',
                      label: 'Facebook'
                    },
                    {
                      value: '3',
                      label: 'Instagram'
                    },
                    {
                      value: '4',
                      label: 'Feiras e eventos'
                    },
                    {
                      value: '5',
                      label: 'Através de lojas de clientes'
                    },
                    {
                      value: '6',
                      label: 'Indicação'
                    },
                    {
                      value: '7',
                      label: 'Outros'
                    },
                  ]}
                />
                <div className='Flex_Column g-2'>
                  <label>Selecione os serviços que sua agência faz:</label>
                  <InputCheckbox
                    style='Default'
                    name='varCheckOpc1'
                    value="true"
                    label="Criação e desenvolvimento de lojas virtuais"
                  />
                  <InputCheckbox
                    name='varCheckOpc2'
                    value="true"
                    label="Criação de banners"
                  />
                  <InputCheckbox
                    name='varCheckOpc3'
                    value="true"
                    label="Otimização para buscadores (SEO)"
                  />
                  <InputCheckbox
                    name='varCheckOpc4'
                    value="true"
                    label="Campanhas patrocinadas em buscadores"
                  />
                  <InputCheckbox
                    name='varCheckOpc5'
                    value="true"
                    label="Campanhas em mídias sociais"
                  />
                  <InputCheckbox
                    name='varCheckOpc6'
                    value="true"
                    label="Gerenciamento de mídias sociais"
                  />
                  <InputCheckbox
                    name='varCheckOpc7'
                    value="true"
                    label="Cadastramento de produtos"
                  />
                  <InputCheckbox
                    name='varCheckOpc8'
                    value="true"
                    label="Configurações gerais da loja virtual"
                  />
                </div>
                <div className='Flex_Row g-5'>
                  <div style={{ width: '100%' }}>
                    <InputSelect
                      name="varTxtTempoAgencia"
                      label='Há quanto tempo sua agência está no mercado?'
                      placeholder='Escolha...'
                      required={true}
                      defaultMaxOptions={1}
                      options={[
                        {
                          value: "1",
                          label: 'Menos de 1 ano'
                        },
                        {
                          value: "2",
                          label: 'Até 3 anos'
                        },
                        {
                          value: "3",
                          label: 'Até 5 anos'
                        },
                        {
                          value: "4",
                          label: 'Mais de 5 anos'
                        },
                      ]}
                    />

                  </div>
                  <div style={{ width: '100%' }}>
                    <InputSelect
                      name='varTxtNumeroFuncionarios'
                      label='Quantos funcionários possui sua agência?'
                      placeholder='Escolha...'
                      required={true}
                      defaultMaxOptions={1}
                      options={[
                        {
                          value: '1',
                          label: 'Apenas eu'
                        },
                        {
                          value: '2',
                          label: 'Até 3 funcionários'
                        },
                        {
                          value: '3',
                          label: 'Até 5 funcionários'
                        },
                        {
                          value: '4',
                          label: 'Até 10 funcionários'
                        },
                        {
                          value: '5',
                          label: 'Mais de 10 funcionários'
                        },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <InputTextArea
                    name='varTxtFaleSobre'
                    required={true}
                    placeholder='Sobre a agência....'
                    label='Fale um pouco da sua agência.'
                  />
                </div>

                <div>
                  <h4>Dados cadastrais</h4>
                </div>

                <div className='pr-5'>
                  <InputText
                    required={true}
                    placeholder='Razão Social'
                    name='varTxtRazaoSocial'
                    size='Half'
                    label='Razão Social'
                  />
                </div>

                <div className='Flex_Row g-5'>
                  <InputText
                    required={true}
                    placeholder='Nome Fantasia'
                    name='varTxtNomeFantasia'
                    size='Half'
                    label='Nome Fantasia'
                  />
                  <InputText
                    required={true}
                    mask={{ mask: '__.___.___/____-__', replacement: { _: /\d/ } }}
                    placeholder='XX.XXX.XXX/XXXX-YY'
                    name='varTxtCNPJ'
                    size='Half'
                    label='CNPJ'
                  />
                </div>

                <div className='Flex_Row g-5'>
                  <InputText
                    mask={{ mask: '_____-___', replacement: { _: /\d/ } }}
                    required={true}
                    placeholder='00000-000'
                    name='varTxtCep'
                    size='Half'
                    label='CEP'
                  />
                  <InputText
                    required={true}
                    placeholder='Avenida Paulista, 123'
                    name='varTxtEndereco'
                    size='Half'
                    label='Endereço completo'
                  />
                </div>

                <div className='Flex_Row g-5'>
                  <InputText
                    mask={{ mask: '__ _____-____', replacement: { _: /\d/ } }}
                    required={true}
                    placeholder='11 99999-9999'
                    name='varTxtFoneFixo'
                    size='Half'
                    label='Telefone'
                  />
                  <InputText
                    required={true}
                    placeholder='agencia@email.com'
                    name='varTxtEmail1'
                    size='Half'
                    type='email'
                    label='E-mail'
                  />
                </div>

                <div>
                  <h4>Dados do responsável</h4>
                </div>

                <div className='pr-5'>
                  <InputText
                    required={true}
                    name='varTxtNomeCompleto'
                    placeholder='Nome Completo'
                    label='Nome completo do responsável'
                    size='Half'
                  />
                </div>

                <div className='Flex_Row g-5'>
                  <InputText
                    mask={{ mask: '___.___.___-__', replacement: { _: /\d/ } }}
                    placeholder='000.000.000-00'
                    required={true}
                    name='varTxtCpfContato'
                    label='CPF'
                    size='Half'
                  />
                  <InputText
                    required={true}
                    type='date'
                    placeholder='31/12/2000'
                    name='varTxtDataNasc'
                    label='Data de nascimento'
                    size='Half'
                  />
                </div>

                <div className='Flex_Row g-5'>
                  <InputText
                    mask={{ mask: '__ _____-____', replacement: { _: /\d/ } }}
                    placeholder='11 99999-9999'
                    required={true}
                    name='varTxtFoneCel'
                    label='Telefone celular'
                    size='Half'
                  />
                  <InputText
                    required={true}
                    placeholder='agencia@email.com'
                    name='varTxtEmail2'
                    type='email'
                    label='E-mail'
                    size='Half'
                  />
                </div>

                <div>
                  <InputCheckbox
                    name='tipo'
                    value='Enviar'
                    label='Aceito os termos e condições'
                    required={true}
                  />
                </div>

                <div>
                  <InputButton type='Save'>Cadastrar</InputButton>
                </div>

              </form>
            </div>
          </div>
        </main>
      </section>
      <BlurContainer />
    </>
  )
}

export default Register