import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { APICall } from 'Service/Api'
import { ContentBlock, ContentList, FloatBlock, Loading } from 'Shared/Components'
import { Graph, Lamp } from 'Shared/Assets/Icons';
import { useAlert, useHeader } from 'Shared/Contexts';
import { CreatingTheme } from 'Components';
import { usePartner } from 'Contexts';

const Lojas = () => {
  const [creatingTheme, setCreatingTheme] = useState(false);
  const [themeStoreId, setThemeStoreId] = useState<string | undefined>();
  const { setCustomAlert } = useAlert();
  const { user, logoutUser } = usePartner();
  const { setHeaderTitle, setHeaderButton } = useHeader();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parceiro/gerenciar', user.PARC_TOKEN],
    queryFn: async () => await APICall(user.PARC_TOKEN, 'parceiro/gerenciar'),
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    setHeaderButton(undefined);
    setHeaderTitle("Lojas gerenciadas");
  }, []);

  useEffect(() => {
    if (isError && (error as any)?.erro == 400) {
      logoutUser();
    }
  }, [isError, error])

  return (
    <>
      {
        isLoading && <Loading />
      }

      {
        isError &&
        (
          <>
            <FloatBlock
              title={(error as any).erro == 404 ? 'Atenção' : 'Erro'}
              type={(error as any).erro == 404 ? 'info' : 'error'}
            >
              <span>{(error as any).mensagem}</span>
            </FloatBlock>
          </>
        )
      }

      {
        creatingTheme &&
        <CreatingTheme
          setCreatingTheme={setCreatingTheme}
          themeID=''
          storeId={themeStoreId}
        />}

      {
        !isLoading &&
        data &&
        data.length > 0 &&
        <>
          <div className='Content_Divisor Flex_Column g-1'>
            <span>
              Abaixo serão listadas as lojas que lhe adicionarem como Agência no painel de controle.
            </span>
            <span>
              Para saber como a loja pode fazer esse processo, clique aqui e veja nosso passo a passo.
            </span>
          </div>
          <ContentList
            dataArray={data}
            columns={{
              "Loja": (store: any) => (
                <>
                  <b>{store.LV_NOME}</b>
                  <small>{store.LVDOMADD_DOMINIO}</small>
                </>
              )
            }}
            actions={{
              "Acessar Loja": {
                function: (store:any) => setCustomAlert({
                  label: 'Deseja continuar?',
                  content: "Você está prestes a abrir o painel da loja " + store.LV_NOME,
                  confirmAction: () => {
                    window.open("https://ws1-adminloja.webstore.net.br/painel/ACESSO_AGENCIA.aspx?hash=" + user.PARC_TOKEN + "&hash2=516516HGJDKSS516516548965161651" + store.LV_ID + "516516HGJDKSS516516548965161651");
                  }
                })
              },
              "Criar Tema": {
                function: (store:any) => {
                  setThemeStoreId(store.LV_ID)
                  setCreatingTheme(true)
                }
              }
            }}
          />
        </>
      }
    </>
  )
}

export default Lojas