import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { APICall } from 'Service/Api'
import { ContentBlock, ContentList, FloatBlock } from 'Shared/Components'
import { usePartner } from 'Contexts'
import Loading from 'Shared/Components/Loading/Loading'
import { useAlert, useHeader, useNotification } from 'Shared/Contexts'
import { IButton } from 'Shared/Utils/Interfaces/Buttons'
import { deleteTheme } from 'Service/Action'
import { CreatingTheme } from 'Components'
import { ClipBoard, Configs, Trashcan } from 'Shared/Assets/Icons'
import { divide } from 'lodash'

const Dashboard = () => {
  const [creatingTheme, setCreatingTheme] = useState(false);
  const [themeID, setThemeID] = useState('');
  const { user, logoutUser } = usePartner();
  const { setHeaderTitle, setHeaderButton } = useHeader();
  const { setNotification } = useNotification();
  const { setCustomAlert } = useAlert();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parceiro/temas', user.PARC_TOKEN],
    queryFn: async () => await APICall(user.PARC_TOKEN, 'parceiro/temas'),
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });

  const mutation = useMutation(deleteTheme, {
    onSuccess: (data: any) => {
      setNotification({
        title: "Sucesso",
        message: "Tema deletado com sucesso!",
        type: "success",
      })
    },
    onError: (message) => {
      setNotification({
        title: "Falha na exclusão de temas",
        message: typeof message == 'string' ? message : 'Erro desconhecido.',
        type: "error",
      })
    }
  })

  useEffect(() => {
    let button: IButton = {
      type: 'Save',
      children: (<>Criar Tema</>),
      label: false,
      onClick: () => {
        setThemeID('');
        setCreatingTheme(true);
      }
    }
    setHeaderTitle('Temas');
    setHeaderButton(button)
  }, []);

  useEffect(() => {
    if (isError && (error as any)?.erro == 400) {
      logoutUser();
    }
  }, [isError, error])

  return (
    <>
      {
        isLoading && <Loading />
      }

      {
        isError &&
        (
          <>
            <FloatBlock
              title={(error as any).erro == 404 ? 'Atenção' : 'Erro'}
              type={(error as any).erro == 404 ? 'info' : 'error'}
            >
              <span>{(error as any).mensagem}</span>
            </FloatBlock>
          </>
        )
      }

      {
        creatingTheme &&
        <CreatingTheme
          themeID={themeID}
          setCreatingTheme={setCreatingTheme}
        />
      }

      {
        !isLoading &&
        data &&
        data.length > 0 &&
        <ContentList
          dataArray={data}
          columns={{
            "Nome/Token": (layout: any) => (
              <>
                <h6>{layout.LAY_APELIDO}</h6>
                <div
                  className='Align_Center g-2'
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigator.clipboard.writeText(layout.LVTOK_TOKEN)}>
                  <small>{layout.LVTOK_TOKEN}</small>
                  <ClipBoard />
                </div>
              </>
            )
          }}
          actions={{
            "Editar Tema": {
              function: (layout) => {
                setThemeID(layout.LAY_ID);
                setCreatingTheme(true);
              }
            },
            "Excluir Tema": {
              function: (layout) => setCustomAlert({
                label: 'Atenção',
                content: (<>
                  <span>Tem certeza que deseja deletar o tema: {layout.LAY_APELIDO}</span>
                  <br></br>
                  <span>Token: {layout.LVTOK_TOKEN}</span>
                </>),
                confirmAction: () => {
                  mutation.mutate({
                    token: user.PARC_TOKEN,
                    id: layout.LAY_ID
                  })
                }
              })
            }
          }}
        />

        // <ContentBlock
        //   title=''
        // >
        //   <div>
        //     <table style={{ width: '100%' }}>
        //       <thead>
        //         <tr>
        //           <td><b>Nome/Token</b></td>
        //           <td><b>Gerenciar</b></td>
        //         </tr>
        //       </thead>
        //       <tbody>
        //         {
        //           data.map((layout: any) => (
        //             <tr>
        //               <td>

        //               </td>
        //               <td>
        //                 <div className='Flex_Row g-5'>
        //                   <div
        //                     style={{ cursor: 'pointer' }}
        //                     onClick={() => {
        //                       setThemeID(layout.LAY_ID);
        //                       setCreatingTheme(true);
        //                     }}>
        //                     <Configs />
        //                   </div>
        //                   <div
        //                     style={{ cursor: 'pointer' }}
        //                     onClick={}>
        //                     <Trashcan />
        //                   </div>
        //                 </div>
        //               </td>
        //             </tr>
        //           ))
        //         }
        //       </tbody>

        //     </table>
        //   </div>
        // </ContentBlock>
      }


    </>
  )
}

export default Dashboard