const Configs2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        id="Caminho_11193"
        data-name="Caminho 11193"
        d="M691,2510h12m0,0a3,3,0,1,0,3-3A3,3,0,0,0,703,2510Zm-6,8h12m-12,0a3,3,0,1,1-3-3A3,3,0,0,1,697,2518Z"
        transform="translate(-690 -2506)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
};

export default Configs2;
