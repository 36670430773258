import { FloatingTag, InputText } from 'Shared/Components'
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react'

const ViewInput = ({ value, label }: { value: string, label: string }) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null); // Ajuste do tipo do estado timerId

  const copyToClipBoard = (string: string) => {
    setPopup(true);
    navigator.clipboard.writeText(string);

    if (timerId) clearTimeout(timerId);

    const newTimerId = setTimeout(() => {
      setPopup(false);
    }, 1000);
    setTimerId(newTimerId);
  }

  useEffect(() => {
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [timerId]);

  return (
    <div>
      <AnimatePresence>
        {
          popup && <FloatingTag label='Copiado para área de transferência' force={true} />
        }
      </AnimatePresence>
      <InputText
        label={label}
        name={'VIEW_' + label}
        readonly={true}
        icon='ClipBoard'
        iconAlign='Right'
        onSearch={copyToClipBoard}
        onFocus={(e: any) => e.target.select()}
        value={value}
      />
    </div>
  )
}

export default ViewInput
