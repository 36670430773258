const Box = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23.901"
      viewBox="0 0 22 23.901"
    >
      <path
        id="Caminho_10995"
        data-name="Caminho 10995"
        d="M1438.445,3473.85l-9.444,5.245m0,0-9.444-5.245m9.444,5.245v10.555m10-6.044v-9.022a2.55,2.55,0,0,0-.056-.744,1.449,1.449,0,0,0-.233-.4,2.544,2.544,0,0,0-.622-.411l-8.222-4.567a2.931,2.931,0,0,0-.645-.3,1.489,1.489,0,0,0-.444,0,2.931,2.931,0,0,0-.645.3l-8.222,4.567a2.544,2.544,0,0,0-.622.411,1.449,1.449,0,0,0-.233.4,2.55,2.55,0,0,0-.056.744v9.022a2.55,2.55,0,0,0,.056.744,1.449,1.449,0,0,0,.233.4,2.544,2.544,0,0,0,.622.411l8.222,4.567a2.931,2.931,0,0,0,.645.3,1.489,1.489,0,0,0,.444,0,2.931,2.931,0,0,0,.645-.3l8.222-4.567a2.544,2.544,0,0,0,.622-.411,1.449,1.449,0,0,0,.233-.4A2.55,2.55,0,0,0,1439,3483.606Z"
        transform="translate(-1418 -3467.144)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Box;
