export async function createLayout({ token, dispositivo, nome, id }: { nome: string, dispositivo: string, token: string, id: string }) {
  const myHeaders = new Headers();
  if (id == "") {
    myHeaders.append("action", "create-theme");
  } else {
    myHeaders.append("action", "update-theme");
  }

  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
  formdata.append("nome", nome);
  formdata.append("dispositivo", dispositivo);
  formdata.append("token", token);
  if (id != "") {
    formdata.append("id", id);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };

  const URL = "https://wsapi.webstore.net.br/parceiro/temas";

  const res = await fetch(URL, requestOptions)
  const data: any = await res.json()

  if (data.erro && data.erro != 200 && data.erro != 404) throw data;
  return data;
}

export async function deleteTheme({ token, id }: { token: string, id: string }) {
  const myHeaders = new Headers();
  myHeaders.append("action", "delete-theme");
  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
  formdata.append("token", token);
  formdata.append("id", id);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };

  const URL = "https://wsapi.webstore.net.br/parceiro/temas";

  const res = await fetch(URL, requestOptions)
  const data: any = await res.json()

  if (data.erro && data.erro != 200 && data.erro != 404) throw data;
  return data;
}


export async function createTheme({ token, dispositivo, storeId }: { dispositivo: string, token: string, storeId: string }) {
  const myHeaders = new Headers();
  myHeaders.append("action", "create-theme");
  myHeaders.append("Accept", "application/json");
  
  const formdata = new FormData();
  formdata.append("token", token);
  formdata.append("tipo-tema", dispositivo);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };

  const URL = "https://wsapi.webstore.net.br/parceiro/loja/" + storeId;

  const res = await fetch(URL, requestOptions)
  const data: any = await res.json()

  if (data.erro && data.erro != 200 && data.erro != 404) throw data;
  return data;
}