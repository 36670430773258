import TypeDoc from "typedoc";
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { GlobalProviders } from "Shared/Contexts"
import { InternalProviders } from "Contexts";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <GlobalProviders>
      <InternalProviders>
        <App />
      </InternalProviders>
    </GlobalProviders>
  </React.StrictMode>
);