import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { usePartner } from 'Contexts'
import { ContentBlock, InputButton, InputText } from 'Shared/Components'
import { useHeader, useNotification } from 'Shared/Contexts'
import { changePassword } from 'Service/Authenticate'

const ChangePassword = () => {
  const { setNotification } = useNotification();
  const { user, logoutUser } = usePartner();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const { setHeaderTitle, setHeaderButton } = useHeader();


  const mutation = useMutation(changePassword, {
    onSuccess: (data:any) => {
      setNotification({
        title: "Sucesso",
        message: "Sua senha foi alterada.",
        type: "success",
      })
    },
    onError: (message) => {
      setNotification({
        title: "Falha",
        message: typeof message == 'string' ? message : 'Erro desconhecido.',
        type: "error",
      })
    }
  })

  const createNewPassword = (e:any) => {
    e.preventDefault();
    mutation.mutate({token: user.PARC_TOKEN, password, newPassword, newPasswordConfirm})
  }

  useEffect(() => {
    setHeaderButton(undefined)
    setHeaderTitle('Alterando a senha de acesso')
  }, [])

  return (
    <>
      <ContentBlock>
        <header>
          <span>Informe os dados abaixo para alterar a senha.</span>
        </header>
        <div>
          <form onSubmit={createNewPassword} className='Flex_Column g-2'>
            <InputText  
              size='Half'
              required={true}
              onChange={(e:any) => setPassword(e.target.value)}
              label='Senha atual'
              name='password'
            />
            <InputText 
              size='Half'
              required={true}
              onChange={(e:any) => setNewPassword(e.target.value)}
              label='Nova senha'
              name='newPassword'
            />
            <InputText 
              size='Half'
              required={true}
              onChange={(e:any) => setNewPasswordConfirm(e.target.value)}
              label='Repita a nova senha'
              name='newPasswordConfirm'
            />
            <div className='mt-3'>
              <InputButton
                type='Add'
              >
                Alterar Senha
              </InputButton>
            </div>
          </form>
        </div>
      </ContentBlock>
    </>
  )
}

export default ChangePassword