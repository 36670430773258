import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePartner } from "../../../Contexts";

const ProtectedRoute = ({ children }: any) => {
  const { user } = usePartner();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
