import { ClipBoard, Close } from "Shared/Assets/Icons";
import { useTheme } from "Shared/Contexts";

import { InputCheckbox, InfoTag } from "Shared/Components";

import { motion } from "framer-motion";
import { lateralPageTransition } from "Shared/Utils/Data/Animations";
import { usePartner } from "Contexts";
import { Link } from "react-router-dom";

const UserMenu = ({ close }: { close: VoidFunction }) => {
  const { theme, toggleTheme } = useTheme();
  const { user, logoutUser } = usePartner();

  return (
    <motion.div
      variants={lateralPageTransition}
      initial="hidden"
      exit="exit"
      animate="visible"
      className="Lateral_Float_Page Flex_Column"
    >
      <div className="Float_Page_Header Justify_Between Align_Center">
        <b>Meu Menu</b>

        <span className="Float_Page_Close" onClick={close}>
          <Close />
        </span>
      </div>

      <a className="Float_Page_Line Justify_Between"><span>{user.PARC_NOME}</span><InfoTag label="Admin" /></a>
      <Link className="Float_Page_Line" onClick={close} to={'/meus-dados'}>Meus dados</Link>
      <Link className="Float_Page_Line" onClick={close} to={'/alterar-senha'}>Alterar senha</Link>
      <div
        className="Float_Page_Line Justify_Between Theme_Switch"
        onClick={toggleTheme}
      >
        <InputCheckbox
          defaultChecked={theme === "dark"}
          label={"Tema Escuro"}
          name="DarkMode"
          style="Switch"
          align="Flex_Row_Reverse Justify_Between"
        />
      </div>
      <a className="Float_Page_Line" onClick={() => {
        close();
        logoutUser()
      }}>Sair</a>
    </motion.div>
  );
};

export default UserMenu;
