export async function loginService({ email, password }:{email:string, password:string}) {
  const formdata = new FormData();
        formdata.append("tipo", "Login");
        formdata.append("user", email);
        formdata.append("password", password);
        
  const requestOptions = {
    method: "POST",
    body: formdata
  };
  
  const res = await fetch("https://wsapi.webstore.net.br/parceiro/login", requestOptions)
  const data:any = await res.json();

  if (data.erro && data.erro != 200) throw data;
  return data;
}

export async function recoverPassService({email}:{email:string}) {
  const formdata = new FormData();
  formdata.append("email", email);

  const requestOptions = {
      method: 'POST',
      body: formdata,
  };

  const res = await fetch("https://wsapi.webstore.net.br/parceiro/lembrar", requestOptions)
  const data:any = await res.json();

  if (data.erro && data.erro != 200) throw data;
  return data;
}

export async function createUser(formObject:any) {
  const formdata = new FormData();
  Object.entries(formObject).forEach((value:any) => formdata.append(value[0], value[1]));

  const requestOptions = {
      method: 'POST',
      body: formdata,
  };

  const res = await fetch("https://wsapi.webstore.net.br/parceiro/cadastro.aspx", requestOptions)
  // const data:any = await res.json();
  const data = await res.text();

  console.log(data, 'testeapi')

  if (data.erro && data.erro != 200) throw data;
  return data;
}

export async function changePassword({token, password, newPassword, newPasswordConfirm}:{token:string, newPasswordConfirm:string, password:string, newPassword:string}) {
  const myHeaders = new Headers();
  myHeaders.append("action", "password-change");
  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
  formdata.append("senhaAtual", password);
  formdata.append("senhaNova", newPassword);
  formdata.append("senhaNova2", newPasswordConfirm);
  formdata.append("token", token);

  var requestOptions = {
      method: 'POST',
      body: formdata,
  };

  const res = await fetch("https://wsapi.webstore.net.br/parceiro/alterar-senha", requestOptions)
  const data:any = await res.json();

  if (data.erro && data.erro != 200) throw data;
  return data;
}
