import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { APICall } from 'Service/Api'
import { usePartner } from 'Contexts'
import { ContentBlock, FloatBlock } from 'Shared/Components'
import Loading from 'Shared/Components/Loading/Loading'
import { useHeader } from 'Shared/Contexts'
import { ViewInput } from 'Components'


const User = () => {
  const { setHeaderTitle, setHeaderButton } = useHeader();
  
  const { user, logoutUser } = usePartner();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['parceiro/pagina', user.PARC_TOKEN],
    queryFn: async () => await APICall(user.PARC_TOKEN, 'parceiro/pagina'),
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    setHeaderTitle('Seus dados');
    setHeaderButton(undefined)
  }, [])

  useEffect(() => {
    if (isError && ((error as any).erro && (error as any).erro != 200)) {
      logoutUser();
    }
  }, [isError, error])
  
  return (
    <>
      {
        isLoading && <Loading />
      }

      {
        isError &&
        (
          <>
            <FloatBlock
              title={(error as any).erro == 404 ? 'Atenção' : 'Erro'}
              type={(error as any).erro == 404 ? 'info' : 'error'}
            >
              <span>{(error as any).mensagem}</span>
            </FloatBlock>
          </>
        )
      }

      {
        data &&
        <ContentBlock>
          <div style={{ display: data.PARC_NOME ? "" : "none" }}>
            <ViewInput
              label="Nome"
              value={data.PARC_NOME}
            />
          </div>
          <div style={{ display: data.PARC_EMAIL ? "" : "none" }}>
            <ViewInput
              label="Email"
              value={data.PARC_EMAIL}
            />
          </div>
          <div style={{ display: data.PARC_URL ? "" : "none" }}>
            <ViewInput
              label="URL"
              value={data.PARC_URL}
            />
          </div>
          <div style={{ display: data.PARC_CODIGO ? "" : "none" }}>
            <ViewInput
              label="Código"
              value={data.PARC_CODIGO}
            />
          </div>
          <div style={{ display: data.PARC_TELEFONE ? "" : "none" }}>
            <ViewInput
              label="Telefone"
              value={data.PARC_TELEFONE}
            />
          </div>
          <div style={{ display: data.PARC_CELULAR ? "" : "none" }}>
            <ViewInput
              label="Celular"
              value={data.PARC_CELULAR}
            />
          </div>
          <div style={{ display: data.PARC_WEBSITE ? "" : "none" }}>
            <ViewInput
              label="Website"
              value={data.PARC_WEBSITE}
            />
          </div>
          <div style={{ display: data.PARC_CPF ? "" : "none" }}>
            <ViewInput
              label="CPF"
              value={data.PARC_CPF}
            />
          </div>
          <div style={{ display: data.PARC_RG ? "" : "none" }}>
            <ViewInput
              label="RG"
              value={data.PARC_RG}
            />
          </div>
          <div style={{ display: data.PARC_DATA_NASCIMENTO ? "" : "none" }}>
            <ViewInput
              label="Data de Nascimento"
              value={data.PARC_DATA_NASCIMENTO}
            />
          </div>
          <div style={{ display: data.PARC_CEP ? "" : "none" }}>
            <ViewInput
              label="CEP"
              value={data.PARC_CEP}
            />
          </div>
          <div style={{ display: data.PARC_ENDERECO ? "" : "none" }}>
            <ViewInput
              label="Endereço"
              value={data.PARC_ENDERECO}
            />
          </div>
          <div style={{ display: data.PARC_BAIRRO ? "" : "none" }}>
            <ViewInput
              label="Bairro"
              value={data.PARC_BAIRRO}
            />
          </div>
          <div style={{ display: data.PARC_CIDADE ? "" : "none" }}>
            <ViewInput
              label="Cidade"
              value={data.PARC_CIDADE}
            />
          </div>
          <div style={{ display: data.PARC_ESTADO ? "" : "none" }}>
            <ViewInput
              label="Estado"
              value={data.PARC_ESTADO}
            />
          </div>
          <div style={{ display: data.PARC_NUMERO ? "" : "none" }}>
            <ViewInput
              label="Número"
              value={data.PARC_NUMERO}
            />
          </div>
          <div style={{ display: data.PARC_COMPLEMENTO ? "" : "none" }}>
            <ViewInput
              label="Complemento"
              value={data.PARC_COMPLEMENTO}
            />
          </div>
          <div style={{ display: data.PARC_DATA_CADASTRO ? "" : "none" }}>
            <ViewInput
              label="Data de Cadastro"
              value={data.PARC_DATA_CADASTRO}
            />
          </div>
          <div style={{ display: data.PARC_RAZAO ? "" : "none" }}>
            <ViewInput
              label="Razão Social"
              value={data.PARC_RAZAO}
            />
          </div>
          <div style={{ display: data.PARC_FANTASIA ? "" : "none" }}>
            <ViewInput
              label="Nome Fantasia"
              value={data.PARC_FANTASIA}
            />
          </div>
          <div style={{ display: data.PARC_CNPJ ? "" : "none" }}>
            <ViewInput
              label="CNPJ"
              value={data.PARC_CNPJ}
            />
          </div>
        </ContentBlock>
      }
    </>
  )
}

export default User