import React from "react";

const TalkBallon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.11"
      height="22"
      viewBox="0 0 22.11 22"
    >
      <path
        id="Caminho_11279"
        data-name="Caminho 11279"
        d="M2966.09,1741.23a8.273,8.273,0,0,1-.09-1.23,8.055,8.055,0,1,1,15.59,2.83c-.07.19-.11.28-.13.35a1.223,1.223,0,0,0-.02.19,2.665,2.665,0,0,0,.03.32l.4,3.27a1.3,1.3,0,0,1,.01.66.527.527,0,0,1-.26.25,1.338,1.338,0,0,1-.66-.02l-3.18-.47c-.17-.02-.25-.04-.33-.04a1.378,1.378,0,0,0-.2.03,2.371,2.371,0,0,0-.36.12,8.083,8.083,0,0,1-2.84.51,8.287,8.287,0,0,1-1.23-.09m-5.19,4.09a5.5,5.5,0,1,0-5.37-5.5,5.629,5.629,0,0,0,.28,1.75c.08.23.11.34.13.42a1.618,1.618,0,0,1,.01.22c-.01.08-.03.17-.07.35L2962,1752l2.99-.41a3.058,3.058,0,0,1,.32-.03.588.588,0,0,1,.19.02,3.24,3.24,0,0,1,.38.12A5.359,5.359,0,0,0,2967.63,1752Z"
        transform="translate(-2961 -1731)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default TalkBallon;