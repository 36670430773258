import { Outlet } from "react-router-dom";
import { Header, LateralBar } from "Shared/Components";
import { BlurContainer } from "Components"
import isMobile from "Shared/Utils/Functions/Mobile";
import { useElementScroll, useWindowHeight} from "Shared/Utils/Functions/Window";
import { useEffect } from "react";
import 'Assets/Styles/_layout.scss'
import { usePartner } from "Contexts";
import { useHeader } from "Shared/Contexts";

const MainLayout = () => {
  const { user } = usePartner();
  const windowHeight = useWindowHeight();
  const { setHeaderTitle } = useHeader(); 
  const {scrollRef, scrollPosition, scrollHeight} = useElementScroll();
  let headerHeight = document.querySelector('header')?.clientHeight || 0;

  useEffect(() => {
    setHeaderTitle('Painel do Parceiro');
    if (user) {
      let IntercomScript = document.createElement('script');
      IntercomScript.innerHTML = `    
      window.intercomSettings = {
        app_id: APP_ID,
        name: "${user.PARC_NOME}",
        email: "${user.PARC_EMAIL}",
        "Parceiro": "Sim",
      };`
      document.querySelector('head')?.append(IntercomScript)
    };
  }, []);

  useEffect(() => {
    headerHeight = document.querySelector('header')?.clientHeight || headerHeight;
  }, [windowHeight]);

  return (
    <>
      <LateralBar />
      <section id='Content_Section' className={isMobile() ? 'Mobile' : ''}>
        <Header
          userName={user.PARC_NOME}
          floating={scrollPosition > 70 && (scrollHeight || 0) > (windowHeight)}
        />
        <main
          style={{height: `${windowHeight - headerHeight}px`}}
          ref={scrollRef}>
          <Outlet />
        </main>
      </section>
      <BlurContainer />
    </>

  );
};

export default MainLayout;
