import { ContentBlock, InputText, InputButton, Loading } from 'Shared/Components'
import { BlurContainer } from "Components"
import isMobile from "Shared/Utils/Functions/Mobile"
import { useEffect, useState } from 'react'
import { usePartner } from 'Contexts'
import { useMutation } from 'react-query'
import { useNotification, useTheme } from 'Shared/Contexts'
import { loginService } from 'Service/Authenticate';
import { Link, useNavigate } from 'react-router-dom'
import AlternateLogo from 'Assets/Icons/AlternateLogo.svg'
import AlternateLogoWhite from 'Assets/Icons/AlternateLogoWhite.svg'
import './Login.scss'

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useTheme();
  const { setNotification } = useNotification();

  const navigate = useNavigate();
  const { setUser } = usePartner();

  const mutation = useMutation(loginService, {
    onMutate: () => {
      setNotification({
        type: "hidden",
      })
    },
    onSuccess: (data: any) => {
      setUser({ ...data, PARC_EMAIL: email });
      setNotification({
        title: "Sucesso no login",
        message: "Você será redirecionado!",
        type: "success",
      })
      setTimeout(() => {
        setNotification(false);
        navigate("/principal");
      }, 1250);
    },
    onError: (message) => {
      setIsLoading(false);
      setNotification({
        title: "Falha no login",
        message: typeof message == 'string' ? message : 'Erro desconhecido.',
        type: "error",
      })
    }
  })

  const verifyForm = (e: any) => {
    e.preventDefault();

    if (!email || !password) {
      setNotification({
        title: "Preencha o formulário conrretamente.",
        type: "error",
      });
    }

    mutation.mutate({ email, password });
  }

  return (
    <>
      <section id='Content_Section' className={isMobile() ? 'Mobile' : ''}>
        <main className='Login_Page'>
          <div className='Float_Logo'>
            <Link to={'/'} className='Justify_Center'>
              <img src={theme == 'default' ? AlternateLogo : AlternateLogoWhite} alt="" />
            </Link>
          </div>
          <div className='Float_Block'>
            <div className='Flex_Column Align_Center'>
              <h1>Painel do parceiro</h1>
            </div>
            <div>
              <form onSubmit={verifyForm} className='Flex_Column g-6 p-2'>
                <InputText type="email" name='email' label='E-mail' placeholder='Digite seu e-mail' showMax={false} max={50} value={email} required onChange={(e) => setEmail(e.target.value)} />
                <InputText type="password" name='password' label='Senha' placeholder='Digite sua senha' showMax={false} value={password} max={50} required onChange={(e) => setPassword(e.target.value)} />
                
                <div>
                  <InputButton type="Add" className='Flex_Row' disabled={isLoading}>
                    { isLoading ? <Loading relative={true} /> : 'Entrar' }
                  </InputButton>
                </div>
                <div className='Justify_Center'>
                <Link className='link Justify_End' to={'/recover'} >Esqueceu a senha?</Link>
                </div>
                <div className='Justify_Center' style={{display: "none"}}>
                  <span>Ainda não é parceiro? <Link to={'/cadastro'}>Cadastre-se</Link></span>
                </div>
              </form>
            </div>
          </div>
        </main>
      </section>
      <BlurContainer />
    </>
  )
}

export default Login