import { useLocation } from 'react-router-dom';
import { partnerRedirect } from 'Service/Api'
import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { Loading } from 'Shared/Components'

const Partners = () => {
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\//, '');

  const { isLoading, isError, data, error } = useQuery({
    queryFn: async () => await partnerRedirect(currentPath),
    retry: false,
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    if (isError && (error as any)?.erro == 404) {
      window.location.href = 'https://www.webstore.com.br/';
    }
  }, [isError, data]);

  useEffect(() => {
    if (!isLoading && data?.PARC_ID && data?.PARC_NOME) {
      window.location.href = 'https://www.webstore.com.br/?parc=' + data.PARC_ID + '&indicacao=' + data.PARC_NOME;
    }
  }, [isLoading, data]);

  return (
    <>
      <Loading />
    </>
  );
};

export default Partners;
