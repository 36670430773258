import Authenticate from "Pages/Authenticate";
import Views from "Pages/Views";

import { RouteObject } from 'react-router-dom'

const pages:RouteObject[] = [
  Views,
  Authenticate,
]

export default pages