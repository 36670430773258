
export async function APICall(token:string, apiPath:string) {
  const myHeaders = new Headers();
  myHeaders.append("action", "view");
  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
        formdata.append("token", token);
        
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  
  const URL = "https://wsapi.webstore.net.br/" + apiPath;
  console.log("test ~ APICall ~ URL:", URL)

  const res = await fetch(URL, requestOptions)
  const data:any = await res.json()

  if (data.erro && data.erro != 200) throw data;
  return data;
}

export async function themes(token:string, apiPath:string) {
  const myHeaders = new Headers();
  myHeaders.append("action", "view");
  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
        formdata.append("token", token);
        
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata
  };
  
  const URL = "https://wsapi.webstore.net.br/" + apiPath;

  const res = await fetch(URL, requestOptions)
  const data:any = await res.json()

  if (data.erro && data.erro != 200 && data.erro != 404) throw data;
  return data;
}

export async function partner(token:string) {
  const formdata = new FormData();
        formdata.append("token", token);
        
  const requestOptions = {
    method: "POST",
    body: formdata
  };
    
  const URL = "https://wsapi.webstore.net.br/parceiro/pagina";

  const res = await fetch(URL, requestOptions)
  const data:any = await res.json();

  console.log(data);

  if (data.erro) throw data;
  return data;
}

export async function partnerRedirect(partnerID:string) {
  const bodyToSend = toQueryString({
    url: partnerID
  })
        
  const requestOptions = {
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: bodyToSend
  };
      
  const URL = "https://wsapi.webstore.net.br/parceiro/pagina";

  const res = await fetch(URL, requestOptions)
  const data:any = await res.json();

  if (data.erro) throw data;
  return data;
}

function toQueryString(obj: any) {
  return Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
}