import React from "react";

const LetterBoxOpen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21.113"
      viewBox="0 0 22 21.113"
    >
      <path
        id="Caminho_11211"
        data-name="Caminho 11211"
        d="M2701.74,2172.63l7.53,4.9a2.349,2.349,0,0,1,.5.37,1.08,1.08,0,0,1,.19.35,2.393,2.393,0,0,1,.04.62v7.33a7.375,7.375,0,0,1-.33,3.16,2.868,2.868,0,0,1-1.31,1.31,7.375,7.375,0,0,1-3.16.33h-10.4a7.375,7.375,0,0,1-3.16-.33,2.868,2.868,0,0,1-1.31-1.31,7.375,7.375,0,0,1-.33-3.16v-7.33a2.393,2.393,0,0,1,.04-.62,1.08,1.08,0,0,1,.19-.35,2.349,2.349,0,0,1,.5-.37l7.53-4.9m3.48,0a4.435,4.435,0,0,0-1.28-.69,2.046,2.046,0,0,0-.92,0,4.435,4.435,0,0,0-1.28.69m3.48,0,7.23,4.7c.34.22.51.33.57.48a.423.423,0,0,1,0,.38c-.06.15-.23.26-.57.48l-7.23,4.7a4.435,4.435,0,0,1-1.28.69,2.046,2.046,0,0,1-.92,0,4.435,4.435,0,0,1-1.28-.69l-7.23-4.7c-.34-.22-.51-.33-.57-.48a.423.423,0,0,1,0-.38c.06-.15.23-.26.57-.48l7.23-4.7"
        transform="translate(-2689 -2170.887)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default LetterBoxOpen;
