import { createContext, useState, useContext, Dispatch, SetStateAction, ReactNode, useEffect } from "react";

interface UserContextType {
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  logoutUser: VoidFunction;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const usePartner = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("usePartner must be used within a userProvider");
  }
  return context;
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : false;
  });

  const logoutUser = () => setUser(false);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const contextValue: UserContextType = {
    user,
    setUser,
    logoutUser
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
