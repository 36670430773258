const Webstore = () => {
  return (
    <svg
      id="Grupo_4423"
      data-name="Grupo 4423"
      xmlns="http://www.w3.org/2000/svg"
      width="30.196"
      height="16.762"
      viewBox="0 0 30.196 16.762"
    >
      <path
        id="Caminho_9"
        data-name="Caminho 9"
        d="M12.488,9.65,10.346,6.339,7.729,2.288a1.971,1.971,0,0,0-.855-.717L3.6.1A1.146,1.146,0,0,0,2.5.18L.527,1.463A1.146,1.146,0,0,0,0,2.426V6.069a1.813,1.813,0,0,0,.293.99l2.637,4.079,2.28,3.524a4.416,4.416,0,0,0,6.3,1.173A4.588,4.588,0,0,0,12.488,9.65ZM3.781,5.906a1.6,1.6,0,1,1,1.6-1.6,1.6,1.6,0,0,1-1.6,1.6Z"
        transform="translate(0 0.004)"
        stroke="none"
      />
      <path
        id="Caminho_10"
        data-name="Caminho 10"
        d="M72.367,13.294l-.357.518C74.044,15.13,77,17.178,78.32,15.143l5.188-8.02a4.39,4.39,0,0,0-7.372-4.764l-5.186,8.02C69.633,12.41,70.332,11.979,72.367,13.294Z"
        transform="translate(-53.946 -0.353)"
        stroke="none"
      />
      <path
        id="Caminho_11"
        data-name="Caminho 11"
        d="M64.5,8.03h0a4.388,4.388,0,0,1-6.077-1.26L56.836,4.352h0l-1.56-2.372A4.39,4.39,0,0,0,49.2.718h0A4.39,4.39,0,0,0,47.936,6.8l5.236,7.979a4.422,4.422,0,0,0,7.39-.018L65.509,7.1A4.305,4.305,0,0,1,64.5,8.03Z"
        transform="translate(-36.167 0.003)"
        stroke="none"
      />
    </svg>
  );
};

export default Webstore;
