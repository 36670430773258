const KnowledgeBase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19.336"
      viewBox="0 0 22 19.336"
    >
      <path
        id="Caminho_11270"
        data-name="Caminho 11270"
        d="M3521,6096.5v-3.01a1,1,0,0,0-.03-.34.455.455,0,0,0-.11-.19,1.149,1.149,0,0,0-.3-.18l-4.56-2.28m-8,1v6.81a2.2,2.2,0,0,0,.06.72,1.023,1.023,0,0,0,.24.38,2.37,2.37,0,0,0,.63.35l6.4,2.93a2.4,2.4,0,0,0,.5.2,1.975,1.975,0,0,0,.34,0,2.4,2.4,0,0,0,.5-.2l6.4-2.93a2.37,2.37,0,0,0,.63-.35,1.023,1.023,0,0,0,.24-.38,2.2,2.2,0,0,0,.06-.72v-6.81m-18-1,9.64-4.82a.837.837,0,0,1,.27-.11.558.558,0,0,1,.18,0,.837.837,0,0,1,.27.11l9.64,4.82-9.64,4.82a.837.837,0,0,1-.27.11.558.558,0,0,1-.18,0,.837.837,0,0,1-.27-.11Z"
        transform="translate(-3505 -6084.563)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default KnowledgeBase;
