import { Card } from 'Shared/Components'
import React from 'react'

const CardTemplate = ({titulo, subtitulo, valor}:{titulo:string, subtitulo:string, valor:string}) => {
  return (
    <>
      <Card>
        <h3>{titulo}</h3>
        <div>
          <span className='Flex_Row'>{subtitulo.replace(/&nbsp;/g, ' ')}&nbsp;</span>
        </div>
        <div>
          <b>{valor}</b>
        </div>
      </Card>
    </>
  )
}

export default CardTemplate